* {
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

body {
  margin: 0 auto;
  width: 90%;
  padding: 4% 0;
}
@media only screen and (min-width: 769px) {
  body {
    width: 80vw;
  }
}
body p {
  font-family: "Roboto", sans-serif;
  line-height: 1.4rem;
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header-container * {
  margin: 2% 0;
}
.header-container .header-title {
  font-family: "Permanent Marker", cursive;
  font-size: 3.6rem;
  text-align: center;
}

.homepage-banner-container {
  margin: 4% 0;
}
.homepage-banner-container .homepage-banner {
  max-height: 150px;
  width: 100%;
  height: auto;
  object-fit: cover;
}
@media only screen and (min-width: 480px) {
  .homepage-banner-container .homepage-banner {
    max-height: 200px;
  }
}

.homepage-bio {
  margin: 2% 0;
}
.homepage-bio p {
  margin: 4% 0;
}

.outside-links {
  display: flex;
  justify-content: center;
  margin: 4% 0;
}
.outside-links .icon-container {
  display: flex;
  align-items: center;
  margin: 0 2%;
}
.outside-links .icon-container p {
  margin-left: 5px;
  color: #8a8a8a;
}

.icon::before {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
}

.outside-links svg {
  color: #8a8a8a;
}

.fa-solid::before, .fa-brands::before {
  font: var(--fa-font-solid);
}

.fa-link::before {
  content: "\f0c1";
}

.fa-envelope::before {
  content: "\f0e0";
}

.fa-linkedin::before {
  content: "\f08c";
}

.fa-github::before {
  content: "\f09b";
}

.resume-download-container {
  margin: 4% 0;
  display: flex;
  justify-content: center;
}
.resume-download-container .resume-download {
  color: #8a8a8a;
  font-family: "Roboto", sans-serif;
}

